/** @jsx jsx */
import { jsx, Box, Grid, Container, Styled} from 'theme-ui'
import {Link} from 'gatsby'

import SEO from "../components/seo"

const Breeders = ({data}) => {

  return(
    <section>
      <SEO title="Breeders" />
      <Container sx={{px: [5,5,0,0]}}>
        <div sx={{px: 5, textAlign: "center", mt: 6}}>
          <Styled.h1>Breeders</Styled.h1>
          <Styled.p>List of recommended breeders coming soon.</Styled.p>
        </div>
      </Container>
    </section>
  )
}

export default Breeders

